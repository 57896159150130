import React, { useState } from 'react';

import CommonLayout from '../components/home/commonLayout';
import { Contents } from '../data/contents';
import Layout from '../components/layout';
import PharmCorpHeading from '../components/typography/heading';
import SEOTag from '../components/seo/seo';
import SolutionsSubTab from '../components/solutions/solutionsSubTab';
import SolutionsTab from '../components/solutions/solutionsTab';

const tabs = ['SmartPharm', 'SmartDoc'];
const subTabs = [
  ['SmartADR', 'SmartDDI', 'SmartDose'],
  ['SmartTx', 'SmartLab', 'SmartCalc'],
];

const SolutionsPage = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [currentSubTab, setCurrentSubTab] = useState(0);

  const handleClickTab = (index) => {
    setCurrentTab(index);
  };

  const HandleClickSubTab = (index) => {
    setCurrentSubTab(index);
  };

  return (
    <Layout selectedMenu='solutions'>
      <SEOTag title='Solutions | Pharmcorps' />
      <CommonLayout>
        <PharmCorpHeading
          lightText='Our'
          boldText='Solutions'
          extraClassNames='my-4 md:mb-4 lg:mb-10'
          underLined={true}
        />
        <div className='h-2 mx-auto mb-10'></div>
        <div className='grid grid-cols-2 mt-6'>
          <div className='col-span-2 flex space-x-8 text-center'>
            <SolutionsTab
              index={0}
              text={tabs[0]}
              currentTab={currentTab}
              setCurrentTab={handleClickTab}
            />
            <SolutionsTab
              index={1}
              text={tabs[1]}
              currentTab={currentTab}
              setCurrentTab={handleClickTab}
            />
          </div>
          <div className='w-full h-20 col-span-2 flex text-center'>
            <SolutionsSubTab
              text={subTabs[currentTab][0]}
              currentSubTab={currentSubTab}
              setCurrentSubTab={HandleClickSubTab}
              index={0}
            />
            <SolutionsSubTab
              text={subTabs[currentTab][1]}
              currentSubTab={currentSubTab}
              setCurrentSubTab={HandleClickSubTab}
              index={1}
            />
            <SolutionsSubTab
              text={subTabs[currentTab][2]}
              currentSubTab={currentSubTab}
              setCurrentSubTab={HandleClickSubTab}
              index={2}
            />
          </div>

          <div
            className='w-full bg-primaryLight1 py-4 col-span-2 text-center px-6 sm:px-10 lg:px-16 xl:px-24 font-light text-gray-500 text-sm md:text-base xl:text-lg rounded-b-3xl pb-10'
            dangerouslySetInnerHTML={{
              __html: Contents.solution[currentTab][currentSubTab],
            }}
          ></div>
        </div>
      </CommonLayout>
      <div className='h-14  mx-auto '></div>
    </Layout>
  );
};

export default SolutionsPage;
