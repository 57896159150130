import React from 'react';
import TabSubHeading from './solutionsTabSubHeading';

const SolutionsSubTab = ({ index, setCurrentSubTab, text, currentSubTab }) => {
  return (
    <div
      className={`w-full h-20 bg-primaryLight1 pt-2 cursor-pointer`}
      onClick={() => setCurrentSubTab(index)}
    >
      <TabSubHeading text={text} index={index} currentSubTab={currentSubTab} />
    </div>
  );
};

export default SolutionsSubTab;
