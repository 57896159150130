import React from 'react';

const TabSubHeading = ({ text, extraClassNames, index, currentSubTab }) => {
  return (
    <div className='flex flex-col items-center'>
      <h1
        className={` text-center text-lg md:text-xl lg:text-2xl pt-4 ${extraClassNames} `}
      >
        {text}
      </h1>
      <div
        className={`bg-primary w-1/2 h-0.5 ${
          index !== currentSubTab && 'hidden'
        }`}
      />
    </div>
  );
};

export default TabSubHeading;
