import React from 'react';

const TabHeading = ({ text, extraClassNames }) => {
  return (
    <h1
      className={`text-center text-xl md:text-2xl lg:text-3xl pt-3 lg:pt-4 ${extraClassNames} text-primary`}
    >
      {text}
    </h1>
  );
};

export default TabHeading;
