import React from 'react';
import TabHeading from './solutionsTabHeading';

const SolutionsTab = ({ index, setCurrentTab, text, currentTab }) => {
  return (
    <div
      className={`w-full h-12 md:h-14 lg:h-16 rounded-t-custom1 cursor-pointer ${
        currentTab === index ? 'bg-primaryLight1' : 'bg-primaryLight2'
      }`}
      onClick={() => setCurrentTab(index)}
    >
      <TabHeading text={text} />
    </div>
  );
};

export default SolutionsTab;
